import React from 'react'
import Routes from 'Routes'
import { datadogRum } from '@datadog/browser-rum';
import { Amplify } from "aws-amplify";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const USERPOOL_ID = process.env.REACT_APP_USERPOOL_ID;
const ENV = process.env.REACT_APP_ENV;

Amplify.configure({
  region: "us-east-2",
  userPoolId: `${USERPOOL_ID}`,
  userPoolWebClientId: `${CLIENT_ID}`,
  authenticationFlowType: "USER_PASSWORD_AUTH",
});

datadogRum.init({
  applicationId: 'f92cd0bf-67f9-4e83-a6c8-150532638190',
  clientToken: 'pubcade7837532e052251f55e32a6cc99fd',
  site: 'datadoghq.com',
  service: 'ehr-join-app',
  env: `${ENV}`,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

export default function App() {
  return (
    <Routes />
  )
}
